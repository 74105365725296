import { ApolloError, gql, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import {
  GqlResponse,
  Picklists,
  TPicklistContext,
  TPicklists,
  TShipCode
} from "ticketing/ticketing.types";
import { TEnterpriseSystem } from "types";

const GET_ENTERPRISE_SYSTEMS = gql`
  query enterpriseSystems {
    enterpriseSystems {
      id
      name
    }
  }
`;

const lookupModeOfTransports = loader("../../ticketing-graphql/lookupModeOfTransports.graphql");

const lookupDeliveryEventTypes = loader("../../ticketing-graphql/lookupDeliveryEvents.graphql");

const lookupUnitOfMeasures = loader("../../ticketing-graphql/lookupUnitOfMeasures.graphql");

const lookupLegalEntities = loader("../../ticketing-graphql/lookupLegalEntities.graphql");

const lookupLogisticsSystems = loader("../../ticketing-graphql/lookupLogisticsSystems.graphql");

const lookupUOMConversions = loader("../../ticketing-graphql/lookupUOMConversions.graphql");

const lookupMeasurementTypes = loader("../../ticketing-graphql/lookupMeasurementTypes.graphql");

const lookupMovementStatuses = loader("../../ticketing-graphql/lookupMovementStatuses.graphql");

const lookupTempUOMs = loader("../../ticketing-graphql/lookupTempUOMs.graphql");

const allShipFromAndShipToCodes = loader(
  "../../ticketing-graphql/allShipFromAndShipToCodes.graphql"
);

const FETCH_POLICY_CACHE_FIRST = "cache-first";
const SELECTED_ENTERPRISE_SYSTEM_KEY = "SELECTED_ENTERPRISE_SYSTEM";
const ALL_SHIP_CODES_STORAGE_KEY = "ALL_SHIP_CODES";

const startOfToday = new Date(new Date().setHours(0, 0, 0, 0)).getTime();

type PicklistFilter = {
  enterpriseSystemId?: number;
};

export const PicklistContext = createContext<TPicklistContext>({
  isReady: false
});

type TEnterpriseSystemResponse = GqlResponse<TEnterpriseSystem[], "enterpriseSystems">;

export const PicklistContextProvider = (props: { children: React.ReactNode }) => {
  const { loading: lEnterpriseSystems } = useQuery<TEnterpriseSystemResponse>(
    GET_ENTERPRISE_SYSTEMS,
    {
      onCompleted: data => {
        setEnterpriseSystems(data.enterpriseSystems);
        if (
          !selectedEnterpriseSystem &&
          data.enterpriseSystems.length > 0 &&
          data.enterpriseSystems[0]
        ) {
          setEnterpriseSystem(data.enterpriseSystems[0]);
        }
      },
      onError: error => setErrors(prev => [...prev, error])
    }
  );
  const [enterpriseSystems, setEnterpriseSystems] = useState<TEnterpriseSystem[]>();

  const [selectedEnterpriseSystem, setSelectedEnterpriseSystem] = useState(() => {
    const val = window.localStorage.getItem(SELECTED_ENTERPRISE_SYSTEM_KEY);
    if (val) {
      return JSON.parse(val) as TEnterpriseSystem;
    }
    return null;
  });

  const skipQuery = (selectedEnterpriseSystem?.id ?? 0) === 0;

  const pickListFilter = useMemo<PicklistFilter>(
    () => ({ enterpriseSystemId: selectedEnterpriseSystem?.id }),
    [selectedEnterpriseSystem]
  );

  const [picklists, setPicklists] = useState<TPicklists>({
    enterpriseSystemId: selectedEnterpriseSystem?.id ?? 0
  });

  useEffect(() => {
    setPicklists(pl => ({
      ...pl,
      enterpriseSystemId: selectedEnterpriseSystem?.id ?? 0
    }));
  }, [selectedEnterpriseSystem]);

  useEffect(() => {
    const val = window.localStorage.getItem(ALL_SHIP_CODES_STORAGE_KEY);
    if (val != null) {
      const shipCodesState = JSON.parse(val);
      if (
        shipCodesState != null &&
        shipCodesState.constructor === Object &&
        Object.keys(shipCodesState).length > 0 &&
        shipCodesState.lastFetchedDay === startOfToday
      ) {
        setPicklists(pl => ({
          ...pl,
          shipFrom: shipCodesState.shipFrom,
          shipTo: shipCodesState.shipTo
        }));
        return;
      }
    }
    setLoadShipCodes(true);
  }, []);

  const [errors, setErrors] = useState<(ApolloError | Error)[]>([]);

  const [loadShipCodes, setLoadShipCodes] = useState(false);
  //Lookup deliveryEventTypes
  const { loading: lDeliveryEventTypes, error: eDeliveryEventTypes } = useQuery<
    GqlResponse<Picklists.PDeliveryEventType[], "deliveryEventTypes">
  >(lookupDeliveryEventTypes, {
    fetchPolicy: FETCH_POLICY_CACHE_FIRST,
    variables: { filter: pickListFilter },
    skip: skipQuery,
    onCompleted: data =>
      setPicklists(prev => ({
        ...prev,
        deliveryEventTypes: data["deliveryEventTypes"]
      })),
    onError: error => setErrors(prev => [...prev, error])
  });

  //Lookup Mode of Transports
  const { loading: lModeOfTransports, error: eModeOfTransport } = useQuery<
    GqlResponse<Picklists.TModeOfTransport[], "modeOfTransports">
  >(lookupModeOfTransports, {
    fetchPolicy: FETCH_POLICY_CACHE_FIRST,
    variables: { filter: pickListFilter },
    skip: skipQuery,
    onCompleted: data =>
      setPicklists(prev => ({
        ...prev,
        modeOfTransports: data["modeOfTransports"]
          .map(m => ({ ...m }))
          .sort((m1, m2) => m1.name.localeCompare(m2.name))
      })),
    onError: error => setErrors(prev => [...prev, error])
  });

  //Lookup Movement statuses
  const { loading: lMovementStatuses, error: eMovementStatuses } = useQuery<
    GqlResponse<Picklists.TMovementStatus[], "movementStatuses">
  >(lookupMovementStatuses, {
    fetchPolicy: FETCH_POLICY_CACHE_FIRST,
    variables: { filter: pickListFilter },
    skip: skipQuery,
    onCompleted: data =>
      setPicklists(prev => ({
        ...prev,
        movementStatuses: data["movementStatuses"]
      })),
    onError: error => setErrors(prev => [...prev, error])
  });

  //Lookup Logistics Systems
  const { loading: lLogisticsSystems, error: eLogisticsSystems } = useQuery<
    GqlResponse<Picklists.PLogisticsSystem[], "logisticsSystems">
  >(lookupLogisticsSystems, {
    fetchPolicy: FETCH_POLICY_CACHE_FIRST,
    variables: { filter: pickListFilter },
    skip: skipQuery,
    onCompleted: data =>
      setPicklists(prev => ({
        ...prev,
        logisticsSystems: data["logisticsSystems"]
      })),
    onError: error => setErrors(prev => [...prev, error])
  });

  //Lookup lookupMeasurementTypes
  const { loading: lMeasurementTypes, error: eMeasurementTypes } = useQuery<
    GqlResponse<Picklists.PMeasurementType[], "measurementTypes">
  >(lookupMeasurementTypes, {
    fetchPolicy: FETCH_POLICY_CACHE_FIRST,
    variables: { filter: pickListFilter },
    skip: skipQuery,
    onCompleted: data =>
      setPicklists(prev => ({
        ...prev,
        measurementTypes: data["measurementTypes"]
      })),
    onError: error => setErrors(prev => [...prev, error])
  });

  //Lookup unitOfMeasures
  const { loading: lUnitOfMeasures, error: eUnitOfMeasures } = useQuery<
    GqlResponse<Picklists.TUnitOfMeasure[], "unitOfMeasures">
  >(lookupUnitOfMeasures, {
    fetchPolicy: FETCH_POLICY_CACHE_FIRST,
    variables: { filter: pickListFilter },
    skip: skipQuery,
    onCompleted: data =>
      setPicklists(prev => ({
        ...prev,
        unitOfMeasures: data["unitOfMeasures"]
      })),
    onError: error => setErrors(prev => [...prev, error])
  });

  //Lookup Unit of Measure Conversions
  const { loading: lConversions, error: eConversions } = useQuery<
    GqlResponse<Picklists.TUomConversion[], "uomConversions">
  >(lookupUOMConversions, {
    fetchPolicy: FETCH_POLICY_CACHE_FIRST,
    skip: skipQuery,
    variables: { filter: pickListFilter },
    onCompleted: data =>
      setPicklists(prev => ({
        ...prev,
        uomConversions: data["uomConversions"]
      })),
    onError: error => setErrors(prev => [...prev, error])
  });

  type TTemperatureEnumValues = {
    enumValues: Picklists.TTemperatureUOM[];
  };
  type TTemperatueEnumResponse = {
    tempUOMs: TTemperatureEnumValues;
  };
  //Lookup Temprature Unit of Measures
  const { loading: lTempUOMs } = useQuery<TTemperatueEnumResponse>(lookupTempUOMs, {
    fetchPolicy: FETCH_POLICY_CACHE_FIRST,
    skip: skipQuery,
    onCompleted: data =>
      setPicklists(prev => ({
        ...prev,
        temperatureUOMs: data.tempUOMs?.enumValues.map(t => ({
          ...t,
          shortName: t.name.charAt(0)
        }))
      })),
    onError: error => setErrors(prev => [...prev, error])
  });

  //LookupLegalEntities
  const { loading: lLegalEntities, error: eLegalEntities } = useQuery<
    GqlResponse<Picklists.TInternalLegalEntity[], "legalEntities">
  >(lookupLegalEntities, {
    fetchPolicy: FETCH_POLICY_CACHE_FIRST,
    variables: {
      filter: {
        ...pickListFilter,
        external: { eq: false },
        businessEntity: { eq: false },
        active: { eq: true }
      }
    },
    skip: skipQuery,
    onCompleted: data =>
      setPicklists(prev => ({
        ...prev,
        legalEntities: data["legalEntities"]
      })),
    onError: error => setErrors(prev => [...prev, error])
  });

  // //Load all ShipFrom and ShipTo
  type GqlResponseAllShipCodes = {
    shipFrom: TShipCode[];
    shipTo: TShipCode[];
  };
  const { loading: lShipCodes, error: eShipCodes } = useQuery<GqlResponseAllShipCodes>(
    allShipFromAndShipToCodes,
    {
      fetchPolicy: FETCH_POLICY_CACHE_FIRST,
      skip: !loadShipCodes || skipQuery,
      onCompleted: data => {
        const shipFrom = data.shipFrom.filter(sc => sc.maps?.length);
        const shipTo = data.shipTo.filter(sc => sc.maps?.length);
        window.localStorage.setItem(
          ALL_SHIP_CODES_STORAGE_KEY,
          JSON.stringify({ lastFetchedDay: startOfToday, shipFrom, shipTo })
        );
        setPicklists(prev => ({
          ...prev,
          shipFrom,
          shipTo
        }));
        setLoadShipCodes(false);
      },
      onError: error => setErrors(prev => [...prev, error])
    }
  );
  const setEnterpriseSystem = useCallback((system: TEnterpriseSystem) => {
    setSelectedEnterpriseSystem(system);
    window.localStorage.setItem(SELECTED_ENTERPRISE_SYSTEM_KEY, JSON.stringify(system));
  }, []);

  const isLoading = [
    lEnterpriseSystems,
    lModeOfTransports,
    lConversions,
    lDeliveryEventTypes,
    lUnitOfMeasures,
    lMeasurementTypes,
    lLegalEntities,
    lLogisticsSystems,
    lMovementStatuses,
    lTempUOMs,
    lShipCodes
  ].some(l => l);

  const isReady = [
    !!picklists.modeOfTransports || !!eModeOfTransport,
    !!picklists.uomConversions || !!eConversions,
    !!picklists.deliveryEventTypes || !!eDeliveryEventTypes,
    !!picklists.unitOfMeasures || !!eUnitOfMeasures,
    !!picklists.measurementTypes || !!eMeasurementTypes,
    !!picklists.legalEntities || !!eLegalEntities,
    !!picklists.logisticsSystems || !!eLogisticsSystems,
    !!picklists.movementStatuses || !!eMovementStatuses,
    !!picklists.shipFrom || !!picklists.shipTo || !!eShipCodes
  ].every(v => Boolean(v));

  const providerValue = React.useMemo<TPicklistContext>(
    () => ({
      picklists,
      isLoading,
      isReady,
      errors,
      enterpriseSystems,
      selectedEnterpriseSystem,
      setEnterpriseSystem
    }),
    [
      picklists,
      isLoading,
      isReady,
      errors,
      enterpriseSystems,
      setEnterpriseSystem,
      selectedEnterpriseSystem
    ]
  );

  return (
    <PicklistContext.Provider value={providerValue}>{props.children}</PicklistContext.Provider>
  );
};

export function usePicklists() {
  const context = useContext<TPicklistContext>(PicklistContext);
  if (!context) {
    throw new Error("usePicklists must be used with in Ticketing module only");
  }
  return context;
}
